import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BannerImg, BannerTop, TitTab, MainWrap, TopBtn, SubTit, MaxWidth2 } from 'Global';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useMoveScrool from 'hooks/useMoveScroll';
import NewsList from './component/NewsList';
import Search from './component/Search';
import useLocalStorage from 'hooks/useLocalStorage';
import Pageing from './component/Pageing';
import axios from 'axios';
import useInput from 'hooks/useInput';
import ReferenceList from './component/ReferenceList';

const News = () => {
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [scrollEvent, setScrollEvent] = useState<number>(0);
  const [list, setList] = useState<any>([]);
  const [tab, setTab] = useLocalStorage('tab', 1);
  const pageVal = Number(searchParams.get('pageNo'));
  const txtVal = searchParams.get('searchText');
  const typeVal = searchParams.get('searchType');

  const [selected, setSelected] = useState('title');
  const [text, onChangeText, setText] = useInput('');

  const tabRef: any = {
    0: useMoveScrool(),
    1: useMoveScrool(),
    2: useMoveScrool(),
    length: 6,
  };

  useEffect(() => {
    tabRef[0].onMoveToElement();
    async function getNotice() {
      try {
        const response = await axios.get(
          `http://api.unic-global.com/board?boardGubun=NOTICE${pageVal ? `&pageNo=${pageVal}` : ''}${typeVal ? `&searchType=${typeVal}` : ''}${
            txtVal ? `&searchText=${txtVal}` : ''
          }&pageSize=6`,
        );
        setList(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    async function getNews() {
      try {
        const response = await axios.get(
          `http://api.unic-global.com/board?boardGubun=NEWS${pageVal ? `&pageNo=${pageVal}` : ''}${typeVal ? `&searchType=${typeVal}` : ''}${
            txtVal ? `&searchText=${txtVal}` : ''
          }&pageSize=6`,
        );
        setList(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    async function getReference() {
      try {
        const response = await axios.get(
          `http://api.unic-global.com/reference?pageSize=10${pageVal ? `&pageNo=${pageVal}` : ''}${txtVal ? `&searchText=${txtVal}` : ''}`,
        );
        setList(response.data);
      } catch (error) {
        console.error(error);
      }
    }

    if (pathname === '/news/notice') {
      setTab(1);
      setList(null);
      if (tab !== 1) {
        navigate(`?pageNo=1`);
        setText('');
        setSelected('title');
      }
      getNotice();
    }
    if (pathname === '/news/news') {
      setTab(2);
      setList(null);
      if (tab !== 2) {
        navigate(`?pageNo=1`);
        setText('');
        setSelected('title');
      }
      getNews();
    }
    if (pathname === '/news/reference') {
      setTab(3);
      setList(null);
      if (tab !== 3) {
        navigate(`?pageNo=1`);
        setText('');
        setSelected('title');
      }
      getReference();
    }
  }, [pathname, tab, search, pageVal, typeVal, txtVal]);

  return (
    <MainWrap>
      <MaxWidth2>
        <BannerTop>
          <h1>
            <span>{t('TR_NEWS_MAIN_TIT_01')}</span>
            <span>{t('TR_NEWS_MAIN_TIT_02')}</span>
          </h1>
        </BannerTop>
        <TitTab className="news">
          <button type="button" className={tab === 1 ? ' active' : ''} onClick={() => navigate('/news/notice?pageNo=1')}>
            {t('TR_NEWS_TAB_01')}
          </button>
          <button type="button" className={tab === 2 ? ' active' : ''} onClick={() => navigate('/news/news?pageNo=1')}>
            {t('TR_NEWS_TAB_02')}
          </button>
          <button type="button" className={tab === 3 ? ' active' : ''} onClick={() => navigate('/news/reference')}>
            {t('TR_NEWS_TAB_03')}
          </button>
        </TitTab>
      </MaxWidth2>
      <BannerImg className="news"></BannerImg>
      <MaxWidth2 ref={tabRef[0].element} className={scrollEvent >= 1 ? ' active' : ''}>
        <Search tab={tab} selected={selected} setSelected={setSelected} text={text} onChangeText={onChangeText} />
        {tab === 1 || tab == 2 ? <NewsList tab={tab} list={list} /> : <ReferenceList list={list} />}
        {list?.list?.length !== 0 && <Pageing list={list} />}
      </MaxWidth2>
    </MainWrap>
  );
};

export default News;
