import React, { useEffect, useState } from 'react';
import { Main04Wrap } from './style';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import removeTags from '../../hooks/removeTag';

type Main04 = {
  titRef04: React.ForwardedRef<HTMLDivElement | null>;
  scrollEvent: number;
};

const Main04 = ({ titRef04, scrollEvent }: Main04) => {
  const { t, i18n } = useTranslation();
  const [list, setList] = useState<any>();
  useEffect(() => {
    async function getNews() {
      try {
        const response = await axios.get(`http://api.unic-global.com/board?boardGubun=NEWS&pageSize=4`);
        setList(response.data.list);
      } catch (error) {
        console.error(error);
      }
    }
    getNews();
  }, []);

  return (
    <Main04Wrap ref={titRef04} className={scrollEvent >= 4 ? 'active' : ''}>
      <div>
        <div className="inner">
          <h2>{t('TR_MAIN_04_TIT')}</h2>
          <div className="top">
            <p>{t('TR_MAIN_04_TIT_SUB')}</p>
            <Link to="/news/news?pageNo=1">MORE</Link>
          </div>
          <div className="scroll">
            {list?.length !== 0 ? (
              <ul>
                {list?.map((item: any) => (
                  <li key={item.boardIdx} className={item.registDaysAgo <= 7 ? 'new' : ''}>
                    <Link to={`/news/view/${item.boardIdx}`}>
                      <span>News</span>
                      <h3>{item.boardTitle}</h3>
                      <p>{removeTags(item.boardContent)}</p>
                    </Link>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="noList">{t('TR_NEWS_MAIN_NO_LIST')}</div>
            )}
            {!list && <div className="noList">Loading...</div>}
          </div>
        </div>
      </div>
    </Main04Wrap>
  );
};

export default Main04;
