import React, { useEffect, useState } from 'react';
import { NewsWrap } from '../style';
import localDateTimeConverter from '../../../hooks/localDateTimeConverter';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import trTag from '../../../hooks/trTag';
import { useTranslation } from 'react-i18next';

const ViewInner = (): any => {
  const [view, setView] = useState<any>('');
  const { pathname } = useLocation();
  const idx = pathname.split('/')[3];
  const { t } = useTranslation();

  useEffect(() => {
    setView('');
    async function getView() {
      try {
        const response = await axios.get(`http://api.unic-global.com/board/${idx}`);
        setView(response.data.obj);
      } catch (error) {
        console.log(error);
      }
    }
    getView();
  }, [idx]);

  if (!view) return false;
  return (
    <>
      <div className="tit">
        <h2>{view.board.boardTitle}</h2>
        <p>{localDateTimeConverter(view.board?.regDateTime)}</p>
      </div>
      <div className="content">
        <p dangerouslySetInnerHTML={{ __html: trTag(view.board?.boardContent) }}></p>
        <div className="link">
          <a href={view.board.originArticleLink} target="_blank">
            {t('TR_NEWS_VIEW_MAIN_TXT')} : {view.board.originArticleLink}
          </a>
        </div>
      </div>
      <div className="listLink">
        {view.nextBoard ? (
          <Link to={`/news/view/${view?.nextBoard?.boardIdx}`}>
            <div>
              <span>{t('TR_NEWS_VIEW_MAIN_NEXT')}</span>
              <h3>{view.nextBoard.boardTitle}</h3>
            </div>
            <span>{localDateTimeConverter(view.nextBoard.regDateTime)}</span>
          </Link>
        ) : (
          <Link to={`/news/view/${view?.board?.boardIdx}`} style={{ pointerEvents: 'none' }}>
            <div>
              <span>{t('TR_NEWS_VIEW_MAIN_NEXT')}</span>
              <h3>{t('TR_NEWS_VIEW_MAIN_NO_NEXT')}</h3>
            </div>
            <span></span>
          </Link>
        )}
        {view.prevBoard ? (
          <Link to={`/news/view/${view.prevBoard.boardIdx}`}>
            <div>
              <span>{t('TR_NEWS_VIEW_MAIN_PREV')}</span>
              <h3>{view.prevBoard.boardTitle}</h3>
            </div>
            <span>{localDateTimeConverter(view.prevBoard.regDateTime)}</span>
          </Link>
        ) : (
          <Link to={`/news/view/${view?.board?.boardIdx}`} style={{ pointerEvents: 'none' }}>
            <div>
              <span>{t('TR_NEWS_VIEW_MAIN_PREV')}</span>
              <h3>{t('TR_NEWS_VIEW_MAIN_NO_PREV')}</h3>
            </div>
            <span></span>
          </Link>
        )}
      </div>

      <Link to={pathname.split('/')[1] === 'news' ? '/news/news?pageNo=1' : '/news/notice?pageNo=1'} className="listBtn">
        {t('TR_NEWS_VIEW_MAIN_LINK_TXT')}
      </Link>
    </>
  );
};

export default ViewInner;
