import React, { useCallback, useEffect, useState } from 'react';
import { DataTable, DownLoadPopUp, MainWrap } from '../style';
import { useTranslation } from 'react-i18next';

const ReferenceList = ({ list }: any) => {
  const [layerOpen, setLayerOpen] = useState<boolean>(false);
  const [layerIdx, setLayerIdx] = useState<any>();
  const onClickLayer = useCallback(
    (id: number) => {
      setLayerOpen(true);
      setLayerIdx(list?.list?.find((v: any) => v.referenceIdx === id));
    },
    [layerOpen, layerIdx, list],
  );
  const closeLayer = useCallback(() => {
    setLayerOpen(false);
    setLayerIdx(null);
  }, [layerOpen, layerIdx]);

  const { t } = useTranslation();

  return (
    <MainWrap>
      <DataTable>
        <table>
          <colgroup>
            <col width="11%" />
            <col width="*" />
            <col width="20%" className="dis-n_m" />
            <col width="20%" className="dis-n_m" />
            <col width="40%" className="dis-c_m" />
          </colgroup>
          <thead>
            <tr>
              <th>No.</th>
              <th>{t('TR_NEWS_MAIN_TABLE_HEAD_TXT_01')}</th>
              <th className="dis-n_m">{t('TR_NEWS_MAIN_TABLE_HEAD_TXT_02')}</th>
              <th>{t('TR_NEWS_MAIN_TABLE_HEAD_TXT_03')}</th>
            </tr>
          </thead>
          <tbody>
            {list?.list?.map((item: any, idx: any) => (
              <tr className="item" key={item.referenceIdx}>
                <td>
                  <span className="num">{list?.totalCount - ((list?.obj?.pageNo - 1) * list?.obj?.pageSize + idx)}</span>
                </td>
                <td>
                  <span className="nameTit">
                    <span className="nameKo">{item.titleKor} </span> <span className="nameEn"> ({item.titleEng})</span>
                  </span>
                </td>
                <td className="dis-n_m">
                  <span className="fileName">{localStorage.getItem('language') === 'KOR' ? item?.referenceFileKor?.name : item?.referenceFileEng?.name}</span>
                </td>
                <td>
                  <button type="button" onClick={() => onClickLayer(item.referenceIdx)} className="downloadBtn">
                    {t('TR_NEWS_MAIN_TABLE_DATA_BUTTON_TXT')}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {!list && <div className="loading">Loading...</div>}
        {list?.list?.length === 0 && <div className="loading">{t('TR_NEWS_MAIN_NO_LIST')}</div>}
      </DataTable>
      {layerOpen && (
        <DownLoadPopUp>
          <div className="layer">
            <button type="button" className="close" onClick={closeLayer}>
              <img src="/images/icon/ico-close.png" alt="closeButton" />
            </button>
            <div>
              <h2>Download </h2>
              <p>Select the language you want to download</p>
              {layerIdx?.referenceFileKor && (
                <a href={`http://api.unic-global.com/file/download/${layerIdx?.referenceFileKor?.fileIdx}`} className="downloadBtn" target="_blank">
                  Korean pdf
                </a>
              )}
              <br className="dis-n" />
              {layerIdx?.referenceFileEng && (
                <a href={`http://api.unic-global.com/file/download/${layerIdx?.referenceFileEng?.fileIdx}`} className="downloadBtn" target="_blank">
                  English pdf
                </a>
              )}
            </div>
          </div>
        </DownLoadPopUp>
      )}
    </MainWrap>
  );
};

export default ReferenceList;
